// main.js

// jQuery scripts in here:
jQuery(function ($) {
	
	
	
	//$('.tiles-widget').tilesWidget();
	
	var hash = window.location.hash;
	if(hash){
		//console.log(hash);
		tile_id = hash.replace("#", "");
		//console.log(tile_id);
		setTimeout(function(){ clickTile(tile_id); }, 500);
	}

	function clickTile(tile_id){
		$('.people [data-resident-id=' + tile_id + ']').click();

		$('html, body').animate({
			scrollTop: $('.people [data-resident-id=' + tile_id + ']').offset().top
		}, 1000);
	}
	// On window resize, call the functions again
	// Use debounce from lodash to stop the resize being called too frequently
	// 250ms is more than enough
	//$(window).on('resize', _.debounce(function() {
		//$(".tiles-widget").tilesWidget();
	//}, 250));
	
	
	$('.residents__col-tiles--home .person').click(function(){
		resident_id = $(this).data('resident-id');
		//console.log(resident_id);
		window.location = "/tenants#" + resident_id;
	});
	
	
	

	$('.space-available .v2-button__enquiry').click(function(e){
		e.preventDefault();
		space_id = $(this).data('space');
		if($('#space-enquiry-' + space_id).hasClass('row-hidden')){
			$('#space-enquiry-' + space_id).show('slow').removeClass('row-hidden');
		} else {
			$('#space-enquiry-' + space_id).hide('slow').addClass('row-hidden');
		}
		//console.log('#space-enquiry-' + space_id);
	});



	$(document).ready(function(){
		$('.bxslider').bxSlider({
			mode: 'fade',
			pagerCustom: '#bx-pager'
		});
    	$('.pod').matchHeight({
    		byRow: false,
    		property: 'height',
    		target: null,
    		remove: false
		});
	});
	
	$(document).ready(function(){
			$(".large-plan").hide();
			$(".show_hide").show();
			$('.show_hide').click(function(e){
				e.preventDefault();
				$(".large-plan").slideToggle();
			});
	});

	$( document ).on( 'keydown', function ( e ) {
		if ( e.keyCode === 27 ) { // ESC
			$( '.large-plan' ).hide();
		}
	});
	
	
	
	$community_masonry = $('.community-grid').masonry({
	  // options
	  itemSelector: '.community-item',
	  // columnWidth: 200
	});

	// make all community items clickable (whole tile)
	$('.community-item, .tweetbox').click(function(){
		permalink = $(this).data('permalink');
		if(permalink){
			window.location = permalink;
		}
	});

	var ajaxpagination = {
    'ajaxurl': '\wp-admin\admin-ajax.php'
	};

	var $postList = $( '.community-grid' );
	var start_from = 20;

	/**
	 * Community page ajax loading
	 * Params
	 * @return  null
	 */
	//var initialise = function () {
    $( '.test-community-ajax' ).on( 'click', function(e) {
        var $this = $( this );
        // var $facts = $factsList.find( '.fact-item' );

        e.preventDefault();

        if (!window.location.origin) {
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
        }


        load_more_posts(start_from);
        start_from += 10;

    });
	
	function load_more_posts(start_from){
		$(window).unbind('scroll'); // stop repeated calls
		if (!window.location.origin) {
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
        }

        $.ajax({
            url: window.location.origin + '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                'action'     : 'load_posts',
                'nbPosts'    : 10,
                'crossDomain': true,
                'start_from' : start_from
            },
            beforeSend: function() {
            }
        })
        .done( function( data ) {

        	//console.log(data);

        	var $items = $(data);
			  // append items to grid
			  $community_masonry.append( $items );

			  	$community_masonry.imagesLoaded(function(){
			  		$community_masonry.masonry( 'appended', $items );
			  	});
			    // add and lay out newly appended items
			    //.masonry( 'appended', $items );

                //console.log( data );
             //    $postList.append(data);
	           	// $community_masonry.masonry('layout');
	        	$(window).scroll(function() {
				   if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
				       //$(window).unbind('scroll');
				       load_more_posts(start_from);
				       //console.log("near bottom!");
				   }
				});

           
        })
        .fail( function( jqXHR, textStatus, errorThrown ) {
            console.dir(jqXHR);
            console.log( 'textStatus = ' + textStatus );
            console.log( 'errorThrown = ' + errorThrown );
        });

        start_from += 10;
	}

	// loading of more content on community page
	$(window).scroll(function() {
	   if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
	       //$(window).unbind('scroll');
			if($('body').hasClass('page-id-2')){
				load_more_posts(start_from);
				//console.log("near bottom!");
			}
	   }
	});
});
